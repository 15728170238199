@import "../../../index.scss";

#mode-button-moon {
  cursor: pointer;

  svg {
    width: 44px;
    height: 44px;

    @media (min-width: 1024px) {
      width: 51px;
      height: 51px;
    }

    @media (min-width: 1440px) {
      width: 60px;
      height: 60px;
    }

    #Oval,
    #MoonCenter {
      fill: white;
    }

    #Moon {
      stroke: $grey1;
    }
  }

  &:hover {
    transition-duration: 0.3s;

    #Oval {
      fill: $grey1;
      transition-duration: 0.3s;
    }

    #Moon {
      stroke: $yellow;
    }

    #MoonCenter {
      fill: $yellow;
    }
  }
}
