@import "../../../index.scss";

#mode-button-sun {
  cursor: pointer;

  svg {
    width: 44px;
    height: 44px;

    @media (min-width: 1024px) {
      width: 51px;
      height: 51px;
    }

    @media (min-width: 1440px) {
      width: 60px;
      height: 60px;
    }

    #Oval,
    #SunCenter {
      fill: $grey1;
    }

    #Sun {
      stroke: white;
    }
  }

  &:hover {
    transition-duration: 0.3s;

    #Oval {
      fill: $grey3;
      transition-duration: 0.3s;
    }

    #Sun {
      stroke: $yellow;
    }

    #SunCenter {
      fill: $yellow;
    }
  }
}
