#logo {
  svg {
    width: 44px;
    height: 44px;

    @media (min-width: 1024px) {
      width: 51px;
      height: 51px;
    }

    @media (min-width: 1440px) {
      width: 60px;
      height: 60px;
    }
  }
  .face {
    fill: var(--navlink);
  }
  path,
  .eye {
    fill: var(--navtext);
  }

  @media (min-width: 768px) {
    &:hover {
      .face {
        fill: var(--logohover);
        transition-duration: 0.3s;
      }

      path,
      .eye {
        fill: var(--navtexthover);
        transition-duration: 0.3s;
      }
    }
  }
}
