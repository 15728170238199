@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

$warmGrey: #fff5eb;
$black: #000000;
$grey1: #333333;
$grey2: #4f4f4f;
$grey3: #828282;
$grey4: #b3b3b3;
$grey5: #fcf8f8;
$white: #ffffff;
$yellow: #ffbf1b;
$coral: #ffc194;
$lightYellow: #fff6d4;
$lightPurple: #bec0e8;
$sunsetUpper: #acc0fb;
$sunsetMiddle: #ffbbc2;
$sunsetLower: #fcd9af;

@font-face {
  font-family: "SpeziaWeb-Medium";
  src: local("SpeziaWeb-Medium"),
    url("./fonts/SpeziaWeb-Medium.woff") format("woff");
}

html {
  height: -webkit-fill-available;
}

body {
  overscroll-behavior-y: contain;
  font-feature-settings: "ss01";
}

#root {
  --textmode: #333333;
  --navlink: #ffffff;
  --navlinkhover: #ffbf1b;
  --navtext: #333333;
  --navtexthover: #ffffff;
  --logohover: #333333;
  --cardBG: #ffffff;
  --imgPlaceholder: #fcf8f8;
  --underlineHover: #ffbf1b;
  background: $warmGrey;

  .normal {
    transition: all 0.3s ease;

    &.white-page,
    .white-page {
      background: white;
      --navlinkhover: #fcf8f8;
    }

    @media (max-width: 768px) {
      &.white-page,
      .white-page {
        --navlink: #fcf8f8;
        .nav-background {
          background: white;
        }
      }
    }
  }

  .sunset-mode {
    --navlinkhover: #fff6d4;
    --navtext: #333333;
    --navtexthover: #333333;
    --logohover: #ffbf1b;
    --cardBG: #fff6d4;
    --imgPlaceholder: #fff6d4;
    animation: 0.3s ease fadeInFromNone;
    background: linear-gradient(
      180deg,
      $sunsetUpper 0%,
      $sunsetMiddle 30.21%,
      $sunsetMiddle 30.22%,
      $sunsetLower 91.67%
    );
    background-attachment: fixed;

    .nav-background {
      background: transparent;
    }
  }

  .dark-mode {
    --textmode: #ffffff;
    --navlink: #333333;
    --navlinkhover: #4f4f4f;
    --navtext: #ffffff;
    --navtexthover: #333333;
    --logohover: #ffffff;
    --imgPlaceholder: #333333;
    --underlineHover: #ffffff;
    background: $black;
    transition: all 0.3s ease;

    .nav-background {
      background: transparent;
    }
  }

  .admin-page {
    .nav-background {
      background: $warmGrey;
    }
  }

  @keyframes fadeInFromNone {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .orientation-even {
    width: 66%;
    height: 66%;
  }

  .orientation-portrait {
    width: 66%;
    height: 100%;
  }

  .orientation-landscape {
    width: 100%;
    height: 66%;
  }
}

.home-full-height {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.slider-one {
  animation: slide 0.7s forwards;
  right: -50vw;

  @keyframes slide {
    100% {
      right: 0;
    }
  }
}

.outcome-image {
  transition: opacity 0.3s;
}

.swiper-image {
  margin-top: 50%;
}

.rotate-up {
  transition-duration: 0.5s;
}

.swiper-slide-next {
  opacity: 50%;
}

.lazy-images {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s ease, opacity 0.3s ease;
}

.mantine-RichTextEditor-root {
  border: none;

  .ql-editor {
    background: $warmGrey;
    font-family: "SpeziaWeb-Medium";
    color: #333333;
  }

  .mantine-RichTextEditor-toolbar {
    border: none;
    background: transparent;
    position: absolute;
    top: -45px;
    right: 0;

    .mantine-RichTextEditor-toolbarInner {
      justify-content: end;
    }
  }
}

@media (min-width: 1024px) {
  video.play-cursor {
    cursor: url("./images/play-button.svg"), auto;
  }

  video.pause-cursor {
    cursor: url("./images/pause-button.svg"), auto;
  }
}

.paragraph .mantine-RichTextEditor-toolbar {
  top: -100px;
}

.about-intro a,
.blog-intro a,
.paragraph-body a,
.slide a {
  cursor: pointer;
  text-decoration: underline;
}
